<template>
  <div class="about">
    <Header />
    <div class="banner">
      <div class="container">
        <div class="warpper">
          <div class="title">About Us</div>
        </div>
      </div>
    </div>
    <div class="about-us">
      <div class="container">
        <div class="warpper">
          <div class="title">How Does It Work?</div>
          <p>
            It’s true QuoteMyCars.com.au is the nation’s premier consumer-direct
            car buying company. We pay cash for cars, trucks, & SUVs in any city
            in any state across the country; and that includes
            QLD;NSW;VIC;SA;WA;ACT;NT ; we pay cash for cars, trucks and suvs
            nationwide. It doesn’t matter if you live in
            Brisbane,Sydney,Melbourne , or any other city; we will come to your
            home, business, or any location you want to buy your car! How’s that
            for convenience!
          </p>
          <div class="line"></div>
          <div class="title" style="text-align: center">But wait!</div>
          <div class="title">
            If you’re not comfortable with us coming to you, we’ve got you
            covered there too.
          </div>
          <p>
            How do we do it? We are partnered with the top 3 vehicle remarketing
            agencies Nationwide; and to make selling your car to us as easy and
            convenient for you as possible, we’ve carefully chosen preferred
            facilities to work with that meet our strict standards. You can take
            your vehicle to one for vehicle inspection, payment for your car,
            truck, van or SUV, and SOLD! Our preferred facility will accept your
            car and pay you for your vehicle on the spot!
          </p>
          <div class="line"></div>
          <div class="title">
            WE SCHEDULE
            <span style="color: #f5c03e">THE APPOINTMENT FOR YOU!</span>
          </div>
          <p>
            Convenience is our top priority for our <b>V.I.P.</b> customers –
            <b>THAT’S YOU!</b> We go to great lengths so we deliver the best
            concierge service customized to you! To find a preferred facility
            location near you, click on the map below.
          </p>
          <div class="line"></div>
          <div class="title">
            100% DIGITAL -
            <span style="color: #f5c03e">100% CONVENIENCE!</span>
          </div>
          <div class="title">Convenience! Convenience! Convenience!</div>
          <p>
            From start to finish our concierge service is conveniently customize
            to your needs to make selling your car FAST, SAFE & EASY! Best yet
            it’s 100% digital and 100% convenience; and the way you want it. All
            you have to do is fill out our short form to get an instant quote to
            get the ball rolling!
          </p>
          <div class="line"></div>
          <div class="title">
            WE SCHEDULE ALL APPOINTMENTS FOR YOU!
            <span style="color: #f5c03e">APPOINTMENTS FOR YOU!</span>
          </div>
          <p>Want your own personal assistant? You Got It!</p>
          <p>
            <b>Our concierge service</b> is like having
            <b>your own personal assistant</b> that does all the work for you!
            It doesn’t matter if we’re coming directly to you or you’re taking
            your vehicle to one of our preferred partnered locations!
            <b>We schedule the appointment</b> for your vehicle inspection, our
            payment to you, and driving away with your car, truck, van or SUV!
          </p>
          <p>
            Our customers are <b>V.I.P.</b> and we appreciate you! What’s more
            is you’ll feel it from hello to thank you for your business!
            Partnering with our preferred locations throughout the AU. and close
            to your home, office or anywhere in the AU. you want is a big part
            of making our <b>FAST, SAFE & EASY</b> process convenient for you!
            You tell one of our <b>LIVE AGENTS</b> where you’re located and give
            us a date and time that <b>works best for you</b>. As
            <b>your personal assistants</b> we immediately
            <b>make all the arrangements</b>, and then circle back to you
            confirming we’ve scheduled your appointment for vehicle inspection,
            payment to you, and pick-up.
          </p>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="container">
        <div class="warpper">
          <div class="info">
            <div class="left">
              <div class="title">Did you know?</div>
              <div class="desc">
                By simplifying the process, we fast track your car sale using
                the convenience of a full concierge service while giving you the
                safety of a trusted source.
              </div>
            </div>
            <div class="right">
              <img src="~@/assets/images/Best-Price-Badge.png" alt="" />
            </div>
          </div>
          <div class="icons">
            <div class="item">
              <div class="icons-left">
                <img src="~@/assets/images/24-Hour-Callback.png" alt="" />
              </div>
              <div class="icons-right">
                <div class="icons-info">SAME DAY</div>
                <div class="icons-info">Call Back</div>
              </div>
            </div>
            <div class="item">
              <div class="icons-left">
                <img src="~@/assets/images/Car-Search.png" alt="" />
              </div>
              <div class="icons-right">
                <div class="icons-info">SAME DAY</div>
                <div class="icons-info">Inspection</div>
              </div>
            </div>
            <div class="item">
              <div class="icons-left">
                <img src="~@/assets/images/Money-Bag.png" alt="" />
              </div>
              <div class="icons-right">
                <div class="icons-info">SAME DAY</div>
                <div class="icons-info">Payment</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="less">
.about {
  width: 100%;
  position: relative;
  padding-top: 120px;
  .banner {
    width: 100%;
    height: 400px;
    background: url("~@/assets/images/BgYellow_1.png");
    background-size: cover;
    .warpper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 40px;
        font-family: Poppins-Black, Poppins;
        font-weight: 900;
        color: #222222;
        line-height: 47px;
      }
    }
  }
  .about-us {
    width: 100%;
    background: #fff;
    .warpper {
      padding: 120px 0;
      box-sizing: border-box;
      p {
        font-family: Poppins-Regular;
        font-weight: 400;
        margin-bottom: 32px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.6);
        line-height: 19px;
        span {
          font-weight: bold;
        }
      }
      .title {
        font-size: 24px;
        font-family: Poppins-ExtraBold, Poppins;
        font-weight: 800;
        color: rgba(0, 0, 0, 0.9);
        margin-bottom: 20px;
      }
      .line {
        width: 100%;
        height: 1px;
        background: #eaeaea;
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }
  }
  .bg-row {
    width: 100%;
    padding: 120px 0;
    box-sizing: border-box;
    background: url("~@/assets/images/BgYellow_1.png");
    background-size: cover;
    .warpper {
      width: 100%;
      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        .left {
          width: 800px;
          .title {
            font-family: Poppins-Black;
            font-size: 40px;
            font-weight: 900;
            line-height: 47px;
            color: #222222;
            margin-bottom: 24px;
          }
          .desc {
            font-family: Poppins-SemiBold;
            font-size: 24px;
            line-height: 28px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.7);
          }
        }
        .right {
          width: 136px;
          height: 136px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .icons {
        width: 800px;
        display: flex;
        .item {
          font-family: Poppins-ExtraBold;
          width: 30%;
          color: #fff;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          font-size: 16px;
          font-weight: 800;
          line-height: 24px;
          margin-right: 20px;
          .icons-left {
            width: 71px;
            height: 71px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.6);
            margin-right: 10px;
            img {
              width: 100%;
              transform: translate(-30%, 40%);
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 690px) {
  .about {
    padding-top: 64px;
    .banner {
      height: 164px;
      .warpper {
        .title {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
    .about-us {
      .warpper {
        padding: 60px 0;
        text-align: left;
        .title {
          font-size: 16px;
          line-height: 19px;
        }
        p {
          font-size: 10px;
          line-height: 16px;
          margin-bottom: 20px;
        }
        .line {
          width: 100%;
          height: 1px;
          background: #eaeaea;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }
    .bg-row {
      padding: 60px 0;
      display: none;
      .warpper {
        .info {
          flex-direction: column;
          .left {
            width: 100%;
            margin-bottom: 24px;
            .title {
              font-size: 28px;
              line-height: 28px;
            }
            .desc {
              font-size: 16px;
              line-height: 16px;
            }
          }
          .right {
            margin: 0 auto 0 0;
          }
        }
        .icons {
          flex-direction: column;
          .item {
            width: 100%;
            margin-left: 20px;
            margin-bottom: 20px;
            .icons-left {
              width: 40px;
              height: 40px;
            }
            .icons-right {
              font-size: 16px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 690px) and (max-width: 999px) {
  .about {
    padding-top: 64px;
    .banner {
      height: 164px;
      .warpper {
        .title {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
    .about-us {
      .warpper {
        padding: 60px 0;
        text-align: left;
        .title {
          font-size: 16px;
          line-height: 19px;
        }
        p {
          font-size: 10px;
          line-height: 16px;
          margin-bottom: 20px;
        }
        .line {
          width: 100%;
          height: 1px;
          background: #eaeaea;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }
    .bg-row {
      padding: 60px 0;
      display: none;
      .warpper {
        .info {
          flex-direction: column;
          .left {
            width: 100%;
            margin-bottom: 24px;
            .title {
              font-size: 28px;
              line-height: 28px;
            }
            .desc {
              font-size: 16px;
              line-height: 16px;
            }
          }
          .right {
            margin: 0 auto 0 0;
          }
        }
        .icons {
          flex-direction: column;
          .item {
            width: 100%;
            margin-left: 20px;
            margin-bottom: 20px;
            .icons-left {
              width: 40px;
              height: 40px;
            }
            .icons-right {
              font-size: 16px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
